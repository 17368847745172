import { OmoikaneConfig, OmoikaneTab } from "./omoikaneCommons";

export const omoikaneSuzuki: OmoikaneConfig = {
    decimals: 2,
    enabledTabs: [
        OmoikaneTab.CUSTOMER_JORUNEY,
        OmoikaneTab.CX_RAY,
        OmoikaneTab.CX_ROAD_MAP,
        OmoikaneTab.CLUSTERS
    ],
    monthdsCoverage: 2,
    indicators: [
        {
            indicator: 'VENTA_CUSTOMER_JOURNEY',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        },
        {
            indicator: 'POSVENTA_CUSTOMER_JOURNEY',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        },
        {
            indicator: 'SENTIMENT',
            key: 'sentiment-venta',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true,
                criticalMomentInclude: [1]
            }
        },
        {
            indicator: 'SENTIMENT',
            key: 'sentiment-postventa',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true,
                criticalMomentInclude: [2]
            }
        },
        {
            indicator: 'VENTA_SATISFACCION',
            key: 'satisfaction-venta',
            keyExtract: [],
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'POSVENTA_SATISFACCION',
            key: 'satisfaction-postventa',
            keyExtract: [],
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'VENTA_NPS',
            key: 'nps-venta',
            keyExtract: [],
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'POSVENTA_NPS',
            key: 'nps-postventa',
            keyExtract: [],
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'SURVEY_COUNT',
            key: 'survey-count-venta',
            keyExtract: [],
            grouped: 'mlCluster',
            extras: {
                omoikane: true,
                groupNameFormat: 'description',
                criticalMomentInclude: [1]
            }
        },
        {
            indicator: 'SURVEY_COUNT',
            key: 'survey-count-postventa',
            keyExtract: [],
            grouped: 'mlCluster',
            extras: {
                omoikane: true,
                groupNameFormat: 'description',
                criticalMomentInclude: [2]
            }
        },
    ],
    sentiment: {
        indicators: {
            Venta: 'sentiment-venta',
            Servicio: 'sentiment-postventa',
            //QR: 'sentiment-qr'
        },
        properties: {
            sentiment: 'sentimentGlobal',
            percentage: 'percentageGlobal',
            sentimentLocal: 'sentimentLocal',
            percentageLocal: 'percentageLocal'
        }
    },
    cxRay: {
        indicators: {
            Venta: [
                { order: 1, name: 'NPS', key: 'nps-venta', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'satisfaction-venta', widgetType: 'gauge' },
            ],
            Servicio: [
                { order: 1, name: 'NPS', key: 'nps-postventa', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'satisfaction-postventa', widgetType: 'gauge' },
            ],
            /*QR: [
                { order: 1, name: 'NPS', key: 'nps-reparacion', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'satisfaction-reparacion', widgetType: 'gauge' },
            ],*/
        }
    },
    clusters: {
        Venta: {
            counters: { indicator: 'survey-count-venta' },
            detail: {
                indicators: [
                    {
                        id: 'csat',
                        indicator: 'VENTA_SATISFACCION',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'nps',
                        indicator: 'VENTA_NPS',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'best-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'top',
                            criticalMomentInclude: [1]
                        },
                    },
                    {
                        id: 'worst-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'bottom',
                            criticalMomentInclude: [1]
                        },
                    },
                    {
                        id: 'last-comments',
                        indicator: 'COMMENTS',
                        keyExtract: [],
                        //grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [1]
                        }
                    },
                    {
                        id: 'alerts',
                        indicator: 'SURVEY_COUNT',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [1]
                        }
                    },
                    {
                        id: 'alerts-counters',
                        indicator: 'ALERT_COUNTERS',
                        keyExtract: [],
                        grouped: 'mlCluster,alertType',
                        extras: {
                            criticalMomentInclude: [1]
                        }
                    }
                ]
            }
        },
        Servicio: {
            counters: { indicator: 'survey-count-postventa' },
            detail: {
                indicators: [
                    {
                        id: 'csat',
                        indicator: 'POSVENTA_SATISFACCION',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'nps',
                        indicator: 'POSVENTA_NPS',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'best-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'top',
                            criticalMomentInclude: [2]
                        },
                    },
                    {
                        id: 'worst-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'bottom',
                            criticalMomentInclude: [2]
                        },
                    },
                    {
                        id: 'last-comments',
                        indicator: 'COMMENTS',
                        keyExtract: [],
                        //grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [2]
                        }
                    },
                    {
                        id: 'alerts',
                        indicator: 'SURVEY_COUNT',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [2]
                        }
                    },
                    {
                        id: 'alerts-counters',
                        indicator: 'ALERT_COUNTERS',
                        keyExtract: [],
                        grouped: 'mlCluster,alertType',
                        extras: {
                            criticalMomentInclude: [2]
                        }
                    }
                ]
            }
        }
    },
    customerJourney: {
        Venta: (data: any) => {
            return data['VENTA_CUSTOMER_JOURNEY']?.data.map((item: any, idx: number) => ({
                key: idx,
                number: (idx + 1).toString(),
                title: item.group,
                items: [
                    { order: 1, name: '', value: item.value }
                ]
            }))
        },
        Servicio: (data: any) => {
            return data['POSVENTA_CUSTOMER_JOURNEY']?.data.map((item: any, idx: number) => ({
                key: idx,
                number: (idx + 1).toString(),
                title: item.group,
                items: [
                    { order: 1, name: '', value: item.value }
                ]
            }))
        },
        /*QR: (data: any) => {
            return data['QR_CUSTOMER_JOURNEY']?.data.map((item: any, idx: number) => ({
                key: idx,
                number: (idx + 1).toString(),
                title: item.group,
                items: [
                    { order: 1, name: '', value: item.value }
                ]
            }))
        }*/
    }
}
