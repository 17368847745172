import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'

export const CJ_JAC_ORDER = {
  10365: 0, // Recepción,
  10366: 1, // Atención
  10367: 2, // Explicación,
  10368: 3, // Instalaciones,
  10369: 4, // Entrega,
  10370: 5, // NPS,
  10376: 0, // Atención y amabilidad,
  10374: 1, // Proceso de recepción,
  10373: 2, // Seguimiento,
  10375: 3, // Proceso de entrega,
  10377: 4, // Tasa de retorno
}

export const getCmID = (code: string): number => {
  if (code === 'venta') return 9951
  else if (code === 'servicio' || code === 'postventa') return 9952
  else return 29
}

export const jacCustomerJourneyMapper = (data: any, extra: any) => {
  return data.map((item: any) => {
    return {
      ...item,
      //label: 'widgets.indicatorId.' + item.groupId,
      weight: CJ_JAC_ORDER[item.groupId],
    }
  })// @ts-ignore
  .asMutable()
  .sort((item1: any, item2: any) => item1.weight - item2.weight )
}

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary
}
