
import logoIamsaSmall from '@assets/logos/iamsa-logo-small.png'
import logoIamsa from '@assets/logos/iamsa-logo.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD, SurveyResponsesTab } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments } from '../dashboard/dashHelper'
import { omoikaneIamsa } from '../omoikane/omoikaneIamsa'
import { iamsaSurveyResponsesTableColumns } from './iamsa/extraConfig'
import deafultHelpDefinition from '../help/defaultHelp'

const epsilonBack = true

export const iamsaConfigs: ClientConfigInfo = {

    //DELTA
    //vocBackendUrl: 'https://iamsa-delta.limetropy.com/api/',
    //EPSILON
    vocBackendUrl: epsilonBack
        ? 'https://iamsa-backend-voc.limetropy.com/api/'
        : 'https://iamsa-delta.limetropy.com/api/',

    //vocBackendUrl: 'http://localhost:9000/api/',
    lcmBackendUrl: 'https://iamsa-delta.limetropy.com/backend/core/api/ui/',

    //DELTA
    publicUrl: undefined,
    //EPSILON usa path /voc
    //publicUrl: '/voc',

    logoSmall: logoIamsaSmall,
    logo: logoIamsa,
    filtersFromDateSubstract: 2,
    filtersToDateSubstract: 0,
    supportsInternationalization: false,
    filters: [
        FILTER_TYPES.DATES,
        FILTER_TYPES.GEOS,
        //FILTER_TYPES.CRITICAL_MOMENTS, 
        FILTER_TYPES.LOGICALS
    ],
    menuDashboards: [DASHBOARDS.DEFAULT],
    msExcelReportExt: 'xls',
    geosRootId: 214,
    logicalsRootId: 15,
    criticalMomentsRootId: 0,
    criticalMomentFilterType: 'select',
    actionPlans: {
        geoLeafLevel: 'BRANCH',
        useWorstTags: true
    },
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    omoikane: omoikaneIamsa,
    ratingRanges: {
        defaultRatingMax: 10,
        defaultRatingMin: 0
    },
    decimals: undefined,
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    useBackDashboards: false,
    metadata: [
        METADADATA_LOAD.GEOS,
        //METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.LOGICALS,
        METADADATA_LOAD.DYNAMIC_REPORT_FIELDS,
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.iamsa,
        default: DASHBOARDS_CONFIGS.iamsa[DASHBOARDS.DEFAULT]
    },
    staticDashboards: {},
    surveyResponses: {
        url: 'survey-responses',
        type: epsilonBack ? 'post-context' : 'get',
        tableColumns: iamsaSurveyResponsesTableColumns,
        tabs: [SurveyResponsesTab.ANSWERED/*, SurveyResponsesTab.INVALID*/],
    },
    alertDetailFields: [
        {
            label: 'Origen - Destino',
            getValue: data => `${data.surveyResponseContext?.surveyOperation?.sOrigen} - ${data.surveyResponseContext?.surveyOperation?.sDestino}`
        },
        {
            label: 'Número de operación',
            getValue: data => data.surveyResponseContext?.surveyOperation?.nOperacion
        },
        {
            label: 'Número de unidad',
            getValue: data => data.surveyResponseContext?.surveyOperation?.nAutobus
        },
    ],
    help: deafultHelpDefinition
}
