
import logoJac from '@assets/logos/jac-logo.png'
import logoJacSmall from '@assets/logos/jac-logo-small.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD, SurveyResponsesTab } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments } from '../dashboard/dashHelper'
import { omoikaneJac } from '../omoikane/omoikaneJac'
import jacHelpDefinition from '../help/jacHelp'

export const jacConfigs: ClientConfigInfo = {
    vocBackendUrl: 'https://jac-backend-voc.limetropy.com/api/',
    lcmBackendUrl: 'https://jac-backend-core.limetropy.com/backend/core/api/ui/',
    //vocBackendUrl: 'http://localhost:9000/api/',
    publicUrl: '/voc',
    logoSmall: logoJacSmall,
    logo: logoJac,
    filtersFromDateSubstract: 2,
    filtersToDateSubstract: 0,
    decimals: undefined,
    filters: [FILTER_TYPES.DATES, FILTER_TYPES.CRITICAL_MOMENTS, FILTER_TYPES.GEOS],
    supportsInternationalization: true,
    msExcelReportExt: 'xlsx',
    menuDashboards: [DASHBOARDS.DEFAULT],
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    geosRootId: 0,
    logicalsRootId: 0,
    criticalMomentsRootId: 29,
    criticalMomentFilterType: 'select',
    actionPlans: {
        geoLeafLevel: 'SUCURSAL',
        useWorstTags: true
    },
    omoikane: omoikaneJac,
    useBackDashboards: false,
    metadata: [
        METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.DYNAMIC_REPORT_FIELDS,
        //METADADATA_LOAD.WORKFLOWS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.jac,
        default: DASHBOARDS_CONFIGS.jac[DASHBOARDS.DEFAULT]
    },
    useDefaultCriticalMoment: true,
    staticDashboards: {},
    surveyResponses: {
        url: 'survey-responses',
        type: 'post',
        tableColumns: undefined,
        tabs: [SurveyResponsesTab.ANSWERED, SurveyResponsesTab.NOT_ANSWERED],
    },
    alertDetailFields: [],
	userPhoneNumber: true,
    help: jacHelpDefinition
}
