import { COLORS_CONFIG_BAR } from "@components/widgets/apacheEchart/Commons"
import { answeredMapper, invalidMapper } from "../dashHelper"
import { getCommonWidget } from '../dashWidgetHelper'
import { getLineDetailedRadar } from "../widgetsLibrary/widgetsLine"
import { commonWidgets, getCmID } from "./commonWidgetsSuzuki"

export default {
  title: "Nacional Postventa Dashboard",
  description: "Dashboard de Nacional Postventa",
   backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets: (filters: FiltersUI) =>  [
    getLineDetailedRadar({
      mainIndicator: 'POSVENTA_SATISFACCION',
      radialIndicators: [
        'POSVENTA_SATISFACCION',
        'POSVENTA_RECOMENDACION',
        'POSVENTA_FACTURACION',
        'POSVENTA_PUNTUALIDAD',
        'POSVENTA_EXPLICACION_TRABAJO'
      ],
      useNormalizedRadial: false,
      radialType: 'byIndicator',
      position: [0, 0],
      size: [4, 3],
      title: 'Satisfacción General',
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
        showRadialChart: true,
        initialVisibleChart: 1,
        /* maxValue: 10, */
      },
      criticalMomentInclude: [getCmID("servicio")],
      indicatorsNames: {
        'POSVENTA_SATISFACCION': 'Satisfacción',
        'POSVENTA_FACTURACION': 'Facturacion',
        'POSVENTA_RECOMENDACION': 'NPS',
        'POSVENTA_PUNTUALIDAD': 'Entrega',
        'POSVENTA_EXPLICACION_TRABAJO': 'Explicacion',
      },
    }),

    {
      title: 'Encuestas',
      type: 'label',
      position: [0, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    {
      title: 'Alertas',
      type: 'label',
      position: [2, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      position: [0, 4],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentInclude: [getCmID("servicio")],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      position: [1, 4],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentInclude: [getCmID("servicio")],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByMonth',
      subTitle: 'Respuesta alertas mensual',
      position: [2, 4],
      size: [1, 2],
      indicator: 'alert-first-response-time',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByWeek',
      subTitle: 'Respuesta alertas semanal',
      position: [3, 4],
      size: [1, 2],
      indicator: 'alert-first-response-time',
      criticalMomentId: getCmID("servicio"),
    }),

    {
      title: 'Análisis de sentimientos',
      type: 'label',
      position: [0, 6],
      size: [4, 1]
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'widgets.sentimentAnalysis',
      position: [0, 7],
      size: [4, 4],
      indicator: 'SENTIMENT',
      criticalMomentId: getCmID("servicio"),
    }),
    {
      title: 'Sucursales',
      type: 'label',
      position: [0, 11],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'widgets.geoLocationMap',
      position: [0, 12],
      size: [2, 2],
      criticalMomentId: getCmID("servicio"),
      indicator: 'POSVENTA_NPS',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsPostventa',
      position: [2, 12],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
        fontSize: '18px',
        //calculatedLimit: (data: any) => Math.min(3, (data.length) / 2),
        limit: 3
      },
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsPostventa',
      position: [3, 12],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
        fontSize: '18px',
        //calculatedLimit: (data: any) => Math.min(3, (data.length) / 2),
        limit: 3
      },
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsPostventa',
      position: [0, 14],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentId: getCmID("servicio"),
      extras: {
        resultType: 'top',
        trunc: "HALF_DOWN",
        topSize: 3
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsPostventa',
      position: [2, 14],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentId: getCmID("servicio"),
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasPostventa',
      position: [0, 15],
      size: [2, 2],
      extraConfigs: { fontSize: '22px' },
      indicator: 'POSVENTA_ROTATIVAS',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasPostventa',
      position: [2, 15],
      size: [2, 2],
      extraConfigs: { fontSize: '22px' },
      indicator: 'POSVENTA_ROTATIVAS',
      criticalMomentId: getCmID("servicio"),
    }),

    {
      title: 'Diagrama de Ishikawa',
      type: 'label',
      position: [0, 17],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      //title: 'Diagrama Ishikawa',
      position: [0, 18],
      size: [4, 2],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [getCmID("servicio")],
    }),

    {
      title: "widgets.otherIndicators",
      type: "label",
      position: [0, 20],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snPostventa',
      position: [0, 21],
      size: [1, 2],
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsPostventa',
      showSample: false,
      position: [1, 21],
      size: [1, 2],
      indicator: 'POSVENTA_NPS',
      criticalMomentId: getCmID("servicio"),
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      position: [2, 21],
      size: [1, 2],
      criticalMomentId: getCmID("servicio"),
      indicator: 'calidad-gauge',
      url: 'survey-responses/values',
      key: 'calidad-datos-posventa',
      mapper: invalidMapper
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasPostventa',
      indicator: 'venta-respuestas-gauge',
      url: 'survey-responses/values',
      key: 'porc-respuestas-posventa',
      mapper: answeredMapper,
      position: [3, 21],
      size: [1, 2],
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      mapper: (data: any, extra: any) => {
        return data.map((item: any) => {
          return {
            ...item,
            label: 'widgets.indicatorId.' + item.groupId
          }
        })
      },
      indicator: 'POSVENTA_CUSTOMER_JOURNEY',
      position: [0, 23],
      size: [4, 2],
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: "widgets.zonalSatisfaction",
      key: 'postventa-satisfaccion-zona',
      indicator: "POSVENTA_SATISFACCION",
      criticalMomentId: getCmID("servicio"),
      position: [0, 25],
      size: [4, 2],
      extraConfigs: {
        colors: COLORS_CONFIG_BAR
      },
      extras: {
        groupByLevel: 2,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: "widgets.distribuidorSatisfaction",
      key: "postventa-satisfaccion-dist",
      indicator: "POSVENTA_SATISFACCION",
      criticalMomentId: getCmID("servicio"),
      position: [0, 27],
      size: [4, 2],
      mapper: (data: any, extra: any) => {
        return data.sort((a: any, b:any) => b.value - a.value).map((item: any) => {
          return {
            ...item,
            label: item.label !== undefined ? item.label.trim().replace('SUZUKI ', '') : undefined,
            group: item.group !== undefined ? item.group.trim().replace('SUZUKI ', '') : undefined
          }
        })
      },
      extraConfigs: {
        colors: COLORS_CONFIG_BAR,
        rotateXAxisLegend: true,
        labelFontSize: 10
      },
      extras: {
        groupByLevel: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.paymentPostventa',
      position: [0, 29],
      size: [1, 2],
      indicator: 'POSVENTA_FACTURACION',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.budgetPostventa',
      position: [1, 29],
      size: [1, 2],
      indicator: 'POSVENTA_PRESUPUESTO',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.repairPostventa',
      position: [2, 29],
      size: [1, 2],
      indicator: 'POSVENTA_REPARACION_VEHICULO',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.repairAvailablePostventa',
      position: [3, 29],
      size: [1, 2],
      indicator: 'POSVENTA_DISPONIBILIDAD_TALLER',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.explicacionTrabajoPostventa',
      position: [0, 31],
      size: [1, 2],
      indicator: 'POSVENTA_EXPLICACION_TRABAJO',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByQuestion',
      title: 'Peso de atributos sobre Satisfacción',
      position: [1, 31],
      size: [3, 2],
      indicator: 'ATRIBUTOS_SATISFACCION_POSTVENTA',
      criticalMomentId: getCmID("servicio"),
      mapper: (data: any) => {
        return [...data].sort((a: any, b:any) => b.value - a.value)
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByMonthRegression',
      title: 'Proyección - Satisfacción',
      position: [0, 33],
      size: [4, 2],
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: getCmID("servicio"),
      mapper: (data: any) => {
        return data &&
          data[0] &&
          data[0].source &&
          data[0].source.map((item: number, idx: any) => ({
            group: item['groupName'],
            label: item['groupName'],
            value: data[0].source[idx].value * 10,
          }))
      },
      extras: {
        aggregation: 'linearRegression'
      },
    }),
    {
      title: 'Palabras',
      type: 'label',
      position: [0, 35],
      size: [4, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 36],
      size: [4, 2],
      criticalMomentId: getCmID("servicio"),
    }),
  ],
} as DasboardConfig
